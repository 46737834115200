import React from 'react';
import classNames from 'classnames';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import { ucFirst } from '../../utils/caseconverters.js';
import styles from './GuideFilter.module.scss';

const GuideFilter = ({
    text = null,
    color = null,
    style = null,
    onClick = () => null,
    active = false,
}) => {
    const hasColor = color ? true : false;
    return (
        <button
            style={style}
            className={classNames(
                styles['GuideFilter'],
                { [styles['GuideFilter--Active']]: active },
                hasColor ? styles['GuideFilter--' + ucFirst(color)] : ''
            )}
            onClick={onClick}>
            {text && (
                <span className={styles['GuideFilter__Text']}>{text}</span>
            )}
        </button>
    );
};

GuideFilter.propTypes = {
    color: PropTypes.oneOf([
        'blue',
        'green',
        'purple',
        'orange',
        'pink',
        'gray',
        'white',
    ]),
    text: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    active: PropTypes.bool,
};

export default GuideFilter;
